
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('li', { 'className': 'grid__item col-lg-4 col-md-6 col-sm-6 col-xs-6 slider__slide' }, _createElement('div', { 'className': 'card-wrapper' }, _createElement('div', {
                    'className': 'card card--product',
                    'tabIndex': '-1'
                }, _createElement('div', { 'className': 'card__inner' }, _createElement('a', {
                    'href': this.url,
                    'className': 'full-unstyled-link'
                }, _createElement('div', { 'className': 'media media--transparent media--square media--hover-effect' }, _createElement('img', {
                    'loading': 'lazy',
                    'className': 'motion-reduce ls-is-cached lazyloaded',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }), this.image2 ? _createElement('img', {
                    'loading': 'lazy',
                    'className': 'motion-reduce ls-is-cached lazyloaded',
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '542'
                }) : null)), _createElement('div', { 'className': 'product-flags' }, this.on_sale ? _createElement('span', {
                    'className': 'flag sale',
                    'key': '841'
                }, 'Sale') : null, this.out_of_stock ? _createElement('span', {
                    'className': 'outstock-overlay',
                    'key': '908'
                }, 'Sold') : null))), _createElement('div', { 'className': 'card-information' }, _createElement('div', { 'className': 'card-information__wrapper' }, _createElement('span', { 'className': 'card-information__text h5' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'full-unstyled-link'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'price' + (this.on_sale ? ' price--on-sale' : '') }, _createElement('dl', {}, _createElement('div', { 'className': 'price__regular' }, _createElement('dd', {}, this.price_varies ? _createElement('span', { 'key': '1461' }, 'From: ') : null, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price))), this.set_qty && !this.price_varies ? _createElement('div', {
                    'className': 'price-item--set',
                    'key': '1640'
                }, '\n                Set of ', this.set_qty, ': ', this.formatPrice(this.price * this.set_qty), '\n              ') : null), _createElement('div', { 'className': 'price__sale' }, _createElement('dd', { 'className': 'price__compare' }, _createElement('s', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.compare_at_price))), _createElement('dd', {}, _createElement('span', { 'className': 'price-item price-item--sale' }, this.formatPrice(this.price))), this.set_qty && !this.price_varies ? _createElement('div', {
                    'className': 'price-item--set',
                    'key': '2199'
                }, '\n                Set of ', this.set_qty, ': ', this.formatPrice(this.price * this.set_qty), '\n              ') : null))), _createElement('div', { 'className': 'thumbnail-buttons' }, isHasQuickviewDialog ? _createElement('div', {
                    'className': 'quickview-btn product-btn',
                    'key': '2486'
                }, _createElement('quickview-opener', {
                    'data-handle': this.handle,
                    'data-modal': '#qvPopupModal',
                    'class': 'product-popup-modal__opener no-js-hidden btn-info quick-view'
                }, _createElement('div', {
                    'className': 'product-popup-modal__button link',
                    'type': 'button',
                    'aria-haspopup': 'dialog',
                    'data-href': '#qvPopupModal',
                    'data-toggle': 'popover',
                    'aria-expanded': 'false'
                }, _createElement('svg', {
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation',
                    'className': 'icon',
                    'viewBox': '0 0 1050 1050'
                }, _createElement('svg', {
                    'x': '26%',
                    'y': '26%'
                }, _createElement('path', { 'd': 'M505.918,236.117c-26.651-43.587-62.485-78.609-107.497-105.065c-45.015-26.457-92.549-39.687-142.608-39.687\n\t\t\tc-50.059,0-97.595,13.225-142.61,39.687C68.187,157.508,32.355,192.53,5.708,236.117C1.903,242.778,0,249.345,0,255.818\n\t\t\tc0,6.473,1.903,13.04,5.708,19.699c26.647,43.589,62.479,78.614,107.495,105.064c45.015,26.46,92.551,39.68,142.61,39.68\n\t\t\tc50.06,0,97.594-13.176,142.608-39.536c45.012-26.361,80.852-61.432,107.497-105.208c3.806-6.659,5.708-13.223,5.708-19.699\n\t\t\tC511.626,249.345,509.724,242.778,505.918,236.117z M194.568,158.03c17.034-17.034,37.447-25.554,61.242-25.554\n\t\t\tc3.805,0,7.043,1.336,9.709,3.999c2.662,2.664,4,5.901,4,9.707c0,3.809-1.338,7.044-3.994,9.704\n\t\t\tc-2.662,2.667-5.902,3.999-9.708,3.999c-16.368,0-30.362,5.808-41.971,17.416c-11.613,11.615-17.416,25.603-17.416,41.971\n\t\t\tc0,3.811-1.336,7.044-3.999,9.71c-2.667,2.668-5.901,3.999-9.707,3.999c-3.809,0-7.044-1.334-9.71-3.999\n\t\t\tc-2.667-2.666-3.999-5.903-3.999-9.71C169.015,195.482,177.535,175.065,194.568,158.03z M379.867,349.04\n\t\t\tc-38.164,23.12-79.514,34.687-124.054,34.687c-44.539,0-85.889-11.56-124.051-34.687s-69.901-54.2-95.215-93.222\n\t\t\tc28.931-44.921,65.19-78.518,108.777-100.783c-11.61,19.792-17.417,41.207-17.417,64.236c0,35.216,12.517,65.329,37.544,90.362\n\t\t\ts55.151,37.544,90.362,37.544c35.214,0,65.329-12.518,90.362-37.544s37.545-55.146,37.545-90.362\n\t\t\tc0-23.029-5.808-44.447-17.419-64.236c43.585,22.265,79.846,55.865,108.776,100.783C449.767,294.84,418.031,325.913,379.867,349.04\n\t\t\tz' })))))) : null, _createElement('div', { 'className': 'addtocart-btn product-btn' }, _createElement('div', {
                    'className': 'cart-btn btn-info' + (this.out_of_stock ? ' sold-out' : ' add-to-cart-js'),
                    'data-variantid': this.variant_ids[0]
                }, _createElement('svg', {
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation',
                    'className': 'icon',
                    'viewBox': '0 0 1050 1050'
                }, _createElement('svg', {
                    'x': '25%',
                    'y': '25%'
                }, _createElement('path', { 'd': 'M194.59,382.711c-35.646,0-64.646,29-64.646,64.646s29,64.646,64.646,64.646c35.646,0,64.646-29,64.646-64.646\n\t\t\t\tS230.235,382.711,194.59,382.711z M194.59,473.215c-14.261,0-25.858-11.597-25.858-25.858c0-14.261,11.597-25.858,25.858-25.858\n\t\t\t\tc14.254,0,25.858,11.597,25.858,25.858C220.448,461.617,208.851,473.215,194.59,473.215z' }), _createElement('path', { 'd': 'M385.941,382.711c-35.646,0-64.646,29-64.646,64.646s29,64.646,64.646,64.646c35.646,0,64.646-29,64.646-64.646\n\t\t\t\tS421.587,382.711,385.941,382.711z M385.941,473.215c-14.261,0-25.858-11.597-25.858-25.858\n\t\t\t\tc0-14.261,11.597-25.858,25.858-25.858c14.261,0,25.858,11.597,25.858,25.858C411.799,461.617,400.202,473.215,385.941,473.215z' }), _createElement('path', { 'd': 'M498.088,126.274c-3.685-4.629-9.27-7.324-15.179-7.324H143.326l-17.629-89.095c-1.545-7.803-7.699-13.873-15.528-15.308\n\t\t\t\tL32.594,0.325C22.038-1.621,11.953,5.368,10.02,15.905s5.042,20.641,15.58,22.574l64.607,11.843l56.914,287.667\n\t\t\t\tc1.797,9.083,9.768,15.631,19.025,15.631h271.512c9.031,0,16.86-6.225,18.896-15.037l45.252-195.876\n\t\t\t\tC503.137,136.947,501.767,130.896,498.088,126.274z M422.233,314.833H182.074l-31.075-157.089h307.519L422.233,314.833z' }))))))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []