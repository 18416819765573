//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-464:_1956,_3696,_7516,_1720,_8784,_4052,_5716,_56;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-464')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-464', "_1956,_3696,_7516,_1720,_8784,_4052,_5716,_56");
        }
      }catch (ex) {
        console.error(ex);
      }