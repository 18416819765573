export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',

  SEARCH_BOX_CLASS: 'search search-modal__form',
  SEARCH_BOX_INPUT_CLASS: 'search__input',
  SEARCH_BOX_BUTTON_CLASS: 'search__button field__button',
  BROWSE_ALL: 'Search for "{{query}}"',
  SEARCH_BOX_CLEAR_BUTTON: 'Clear',

  VEHICLE_CATEGORY_WRAPPER_CLASS: 'collection-list-section',

  PRODUCT_REPEATER_CLASS: 'collection',

  GARAGE_TITLE: '',

  DISCARD_VEHICLE: 'Clear',
  CHANGE_VEHICLE: 'Change',
};
